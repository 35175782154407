import routeKeys from '../routes/keys';

export default {
  copyright: '© Centrale Montecatini',
  links: [
    {
      text: 'Home',
      path: routeKeys.Landing,
    },
    // {
    //   text: 'Menu Bar',
    //   path: routeKeys.MenuBar,
    // },
    // {
    //   text: 'Menu Ristorante',
    //   path: routeKeys.MenuRistorante,
    // },
    {
      text: 'Menu festività',
      path: 'https://centralemontecatini.it/assets/docs/menu-feste.pdf',
    },
    {
      text: 'Menu',
      path: 'https://centralemontecatini.it/assets/docs/menu-ristorante.pdf',
    },
    {
      text: 'Ristorante',
      path: routeKeys.Ristorante,
    },
    {
      text: 'Cocktails & Aperitivi',
      path: routeKeys.Drinks,
    },
    {
      text: 'Bar Pasticceria',
      path: routeKeys.Pasticceria,
    },
    {
      text: 'Contatti',
      path: routeKeys.Contatti,
    },
  ],
};
